import styled from "styled-components";

export const FooterContainer = styled.footer`
    height: 15px;
    width: 100vw;
    
    padding: 0 65px;
    
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 900px) {
        padding: 0;
    }
`;

export const FooterContent = styled.div`
    width: 1024px;
    height: 80px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    @media(max-width: 900px) {
        width: 100vw;

        padding: 0;
    }
`;