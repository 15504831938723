import { GrReactjs, GrDocker } from 'react-icons/gr';
import { TbBrandNextjs, TbBrandReactNative } from 'react-icons/tb';
import { ImGit } from 'react-icons/im';
import { FaSass, FaNode, FaBootstrap, FaCss3Alt, FaHtml5, FaPython, FaLinux } from 'react-icons/fa';
import {
  SiTypescript,
  SiMysql,
  SiNestjs,
  SiStyledcomponents,
  SiJavascript,
  SiFlutter,
} from 'react-icons/si';

export const technologiesDescription = [
    {
        name: 'React JS',
        Component: () => <GrReactjs/>,
        experienceTime: { 'pt': '3 anos e 6 meses', 'en': '3 years and 6 months' },
        description: {
            'pt': 'O React é uma biblioteca front-end JavaScript de código aberto com foco em criar interfaces de usuário em páginas web.',
            'en': 'React is an open-source JavaScript front-end library focused on creating user interfaces on web pages'
        }
    },
    {
        name: 'Next JS',
        Component: () => <TbBrandNextjs/>,
        experienceTime: { 'pt': '3 anos', 'en': '3 years' },
        description: {
            'pt': 'Next.js é uma estrutura da web de desenvolvimento front-end React de código aberto criada por Vercel que permite funcionalidades como renderização do lado do servidor e geração de sites estáticos para aplicativos da web baseados em React.',
            'en': 'Next.js is an open-source React front-end development web framework created by Vercel that enables functionality such as server-side rendering and static website generation for React-based web applications.'
        }
    },
    {
        name: 'Nest JS',
        Component: () => <SiNestjs/>,
        experienceTime: { 'pt': '3 anos', 'en': '3 years' },
        description: {
            'pt': 'NestJS é um framework Node.js de código aberto destinado ao desenvolvimento de aplicativos do lado do servidor.',
            'en': 'NestJS is an open-source Node.js framework intended for developing server-side applications.'
        }
    },
    {
        name: 'Node JS',
        Component: () => <FaNode/>,
        experienceTime: { 'pt': '3 anos e 6 meses', 'en': '3 years and 6 months' },
        description: {
            'pt': 'Node.js é um software de código aberto, multiplataforma, baseado no interpretador V8 do Google e que permite a execução de códigos JavaScript fora de um navegador web.',
            'en': `Node.js is open source, cross-platform software, based on Google's V8 interpreter and which allows JavaScript code to be executed outside of a web browse.`
        }
    },
    {
        name: 'React Native',
        Component: () => <TbBrandReactNative/>,
        experienceTime: { 'pt': '2 anos e 6 meses', 'en': '2 years and 6 months' },
        description: {
            'pt': 'React Native é uma biblioteca Javascript criada pelo Facebook. É usada para desenvolver aplicativos para os sistemas Android e iOS de forma nativa.',
            'en': `React Native is a Javascript library created by Facebook. It is used to develop applications for Android and iOS systems natively.`
        }
    },
    {
        name: 'Flutter',
        Component: () => <SiFlutter/>,
        experienceTime: { 'pt': '2 anos', 'en': '2 years' },
        description: {
            'pt': 'Flutter é um kit de desenvolvimento de interface de usuário, de código aberto, criado pela empresa Google em 2015, baseado na linguagem de programação Dart, que possibilita a criação de aplicativos compilados nativamente, para os sistemas operacionais Android, iOS, Windows, Mac, Linux e, Fuchsia e Web.',
            'en': `Flutter is an open source user interface development kit, created by Google in 2015, based on the Dart programming language, which enables the creation of natively compiled applications for the Android, iOS, Windows, Mac, Linux and, Fuchsia and Web.`
        }
    },
    {
        name: 'JavaScript',
        Component: () => <SiJavascript/>,
        experienceTime: { 'pt': '4 anos', 'en': '4 years' },
        description: {
            'pt': 'JavaScript é uma linguagem de programação interpretada estruturada, de script em alto nível com tipagem dinâmica fraca e multiparadigma',
            'en': `JavaScript is a structured, high-level scripting, weakly dynamically typed, multi-paradigm interpreted programming language`
        }
    },
    {
        name: 'TypeScript',
        Component: () => <SiTypescript/>,
        experienceTime: { 'pt': '3 anos', 'en': '3 years' },
        description: {
            'pt': 'TypeScript é uma linguagem de programação de código aberto desenvolvida pela Microsoft. É um superconjunto sintático estrito de JavaScript e adiciona tipagem estática opcional à linguagem.',
            'en': `TypeScript is an open source programming language developed by Microsoft. It is a strict syntactical superset of JavaScript and adds optional static typing to the language.`
        }
    },
    {
        name: 'HTML5',
        Component: () => <FaHtml5/>,
        experienceTime: { 'pt': '4 anos', 'en': '4 years' },
        description: {
            'pt': `HTML é uma linguagem de marcação utilizada na construção de páginas na Web.`,
            'en': `HTML is a markup language used to build web pages.`
        }
    },
    {
        name: 'CSS3',
        Component: () => <FaCss3Alt/>,
        experienceTime: { 'pt': '4 anos', 'en': '4 years' },
        description: {
            'pt': `CSS3 é a terceira mais nova versão das famosas Cascading Style Sheets, pela qual se define estilos para um projeto web.`,
            'en': `CSS3 is the third newest version of the famous Cascading Style Sheets, which allows you to define styles for a web project.`
        }
    },
    {
        name: 'Sass',
        Component: () => <FaSass/>,
        experienceTime: { 'pt': '3 anos', 'en': '3 years' },
        description: {
            'pt': 'Sass é uma linguagem de folhas de estilo concebida inicialmente por Hampton Catlin e desenvolvida por Natalie Weizenbaum.',
            'en': `Sass is a stylesheet language first conceived by Hampton Catlin and developed by Natalie Weizenbaum.`
        }
    },
    {
        name: 'Bootstrap',
        Component: () => <FaBootstrap/>,
        experienceTime: { 'pt': '3 anos e 5 meses', 'en': '3 years and 5 months' },
        description: {
            'pt': 'Bootstrap é um framework web com código-fonte aberto para desenvolvimento de componentes de interface e front-end para sites e aplicações web, usando HTML, CSS e JavaScript, baseado em modelos de design para a tipografia, melhorando a experiência do usuário em um site amigável e responsivo.',
            'en': `Bootstrap is an open source web framework for developing interface and front-end components for websites and web applications, using HTML, CSS and JavaScript, based on design models for typography, improving the user experience on a website friendly and responsive.`
        }
    },
    {
        name: 'Styled-components',
        Component: () => <SiStyledcomponents/>,
        experienceTime: { 'pt': '3 anos e 6 meses', 'en': '3 years and 6 months' },
        description: {
            'pt': 'Styled components é uma biblioteca que possibilita escrever códigos CSS dentro do JavaScript.',
            'en': `Styled components is a library that makes it possible to write CSS code within JavaScript.`
        }
    },
    {
        name: 'Docker',
        Component: () => <GrDocker/>,
        experienceTime: { 'pt': '3 anos', 'en': '3 years' },
        description: {
            'pt': 'Docker é um conjunto de produtos de plataforma como serviço que usam virtualização de nível de sistema operacional para entregar software em pacotes chamados contêineres.',
            'en': `Docker is a set of platform-as-a-service products that use operating system-level virtualization to deliver software in packages called containers.`
        }
    },
    {
        name: 'Git',
        Component: () => <ImGit/>,
        experienceTime: { 'pt': '3 anos', 'en': '3 years' },
        description: {
            'pt': 'Git é um sistema de controle de versões distribuído, usado principalmente no desenvolvimento de software.',
            'en': `Git is a distributed version control system mainly used in software development.`
        }
    },
    {
        name: 'MySQL',
        Component: () => <SiMysql/>,
        experienceTime: { 'pt': '3 anos', 'en': '3 years' },
        description: {
            'pt': 'O MySQL é um sistema de gerenciamento de banco de dados, que utiliza a linguagem SQL como interface',
            'en': `MySQL is a database management system that uses the SQL language as an interface`
        }
    },
    {
        name: 'Linux',
        Component: () => <FaLinux/>,
        experienceTime: { 'pt': '3 anos e 6 meses', 'en': '3 years and 6 months' },
        description: {
            'pt': 'Linux é um termo popularmente empregado para se referir a sistemas operativos ou sistemas operacionais que utilizam o Kernel Linux.',
            'en': `Linux is a term popularly used to refer to operating systems or operating systems that use the Linux Kernel.`
        }
    },
    {
        name: 'Python',
        Component: () => <FaPython/>,
        experienceTime: { 'pt': '2 anos e 2 meses', 'en': '2 years and 2 months' },
        description: {
            'pt': 'Python é uma linguagem de programação de alto nível e de uso geral.',
            'en': `Python is a high-level, general-purpose programming language.`
        }
    },
]