import { useState } from "react";
import { Link } from "react-scroll";
import { GiHamburgerMenu } from 'react-icons/gi'; 
import { IoCloseSharp } from 'react-icons/io5';
import { SocialIcon } from "../Icons";
import { HeaderCont, MenuIcon, HeaderGroup, HeaderList, HeaderListItem, IconsCont } from "./style"; 
import { SwitchThemeMode } from "../Switch";
import { TranslateToggle } from "../TranslateToggle";
import { useTranslateContext } from "../../core/providers/translate";

const headerItems = [
  { id: 0, title: { pt: "Início", en: 'Home' }, to: 'início' },
  { id: 1, title: { pt: "Sobre", en: 'About' }, to: 'sobre' },
  { id: 2, title: { pt: "Tecnologias", en: 'Skills' }, to: 'tecnologias' },
  { id: 3, title: { pt: "Projetos", en: 'Projects' }, to: 'projetos' },
];

const HeaderContainer = ({ menuIsOpen, setMenuIsOpen, children }) => (
  <HeaderCont className={`${menuIsOpen && 'active'}`}>
    <MenuIcon onClick={() => setMenuIsOpen(!menuIsOpen)} className={`${menuIsOpen && 'active'}`}>
      {!menuIsOpen ? <GiHamburgerMenu/> : <IoCloseSharp/>}
    </MenuIcon>
    {children}
  </HeaderCont>
);

export const Header = () => {
  const { currentLanguage } = useTranslateContext();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return  <HeaderContainer {...{menuIsOpen, setMenuIsOpen}}>
    <HeaderGroup>
      <HeaderList>
        {headerItems && headerItems.map((item) => (
          <HeaderListItem
            key={item.id}
          >
            <Link
              to={`${item.to}`}
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              {item?.title[currentLanguage || 'pt']}
            </Link>
        </HeaderListItem>
        ))}
      </HeaderList>
      <IconsCont>
        <SwitchThemeMode/>
        <TranslateToggle current='pt'/>
        <SocialIcon iconType="github" />
        <SocialIcon iconType="linkedin" />
      </IconsCont>
    </HeaderGroup>
  </HeaderContainer>
};
