import {
  CardContainer,
  IconContent,
  IconSkillCont,
} from './style';

const CardCont = ({ id, children, className, style, onMouseOver, onMouseOut }) => {
  return <CardContainer
    id={id}
    className={className}
    style={style}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
  >
    {children}
  </CardContainer>
}

export const SkillCard = ({ className, onHover, onOut, Icon }) => {
  return <IconSkillCont className={className} onMouseOver={onHover} onMouseOut={onOut}>
    <IconContent className={className}>
      <Icon />
    </IconContent>
  </IconSkillCont>
}

export const Card = ({ title = 'Experiência', subtitle, comments, description, currentLanguage = 'pt' }) => {
  return <CardCont className="primary__card">
    <h3>{title}</h3>
    <h5>{currentLanguage === 'pt' ? 'Tecnologia' : 'Technology'}: <span>{subtitle}</span></h5>
    <h5>{currentLanguage === 'pt' ? 'Tempo' : 'Time'}: <span>{comments}</span></h5>
    <p>{description}</p>
  </CardCont>
}