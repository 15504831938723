import { Container, Content, ColumnContainer, BorderContainer } from './style';

export const DefaultContainer = ({ children, id }) => {
    return <Container id={id}>
        <Content>
            {children}
        </Content>
        <BorderContainer />
    </Container>
}

export const Column = ({ children }) => {
    return <ColumnContainer>{children}</ColumnContainer>
}