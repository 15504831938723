import styled from "styled-components";

export const HomeContainer = styled.div`
    width: 100%;
    min-height: 90vh;

    display: grid;
    grid-template-columns: 3fr 1fr;

    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const HomeContent = styled.section`
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h1 {
        &:nth-child(1) {
            color: ${({ theme }) => theme.mainText};
        }
    }

    div:first-child::before {
        width: 90vmin;
        height: 65vmin;
        max-height: 50%;
        content: "";

        position: absolute;
        top: 20%;
        right: 15%;
        opacity: .3;

        z-index: -9;

        animation: anim 25s infinite;
        border-radius: 20% 90% 73% 47% / 26% 46% 54% 44%;
        box-shadow: 0 -2vmin 4vmin ${({ theme }) => theme.bubble.tertiary} inset, 0 1vmin 4vmin ${({ theme }) => theme.bubble.secondary} inset,
            0 -2vmin 7vmin ${({ theme }) => theme.bubble.tertiary} inset;

        @media (max-width: 600px) {
            top: 30%;
            right: 6%;
            opacity: .5;
        }
    }

    @media (max-width: 600px) {
        h1 {
            font-size: 32px;
        }
    }
`;


