import { transparentize } from "polished";
import styled from "styled-components";

export const HeaderContainer = styled.header`
  width: 100vw;
  height: 100px;
  
  position: fixed;
  top: 0;
  left: 0;

  z-index: 100;

  background-color: ${({ theme }) => theme.primaryBackground};
`;

export const HeaderContent = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1140px;

  margin: 0 auto;
  padding: 0 65px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 900px) {
    padding: 0 20px;
  }
`;

export const IconsCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &::first-child {
    margin-right: 5px;
  }
`;

export const LeftArea = styled.section`
  height: 100%;
  width: 100%;
  
  display: flex;
  align-items: center;
  gap: 10px;

  .icon__container {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    transition: opacity .2s;
    
    font-size: 1.5rem;
    color: ${({ theme }) => transparentize(.2, theme.secondaryText)};

    &:hover {
      opacity: .5;
    }
  }
`;

export const SearchContainer = styled.div`
  width: 25rem;
  height: 2.5rem;

  border-radius: 10rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid ${({ theme }) => transparentize(.6, theme.secondaryText)};

  span {
    height: 100%;
    padding: 0 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1rem;

    color: ${({ theme }) => transparentize(.6, theme.secondaryText)};
  }

  @media(max-width: 900px) {
    display: none;
  }
`;

export const Search = styled.input`
  height: 100%;
  width: 100%;

  padding: 0 2rem;
  border-radius: 10rem;
  border: none;

  background-color: transparent;
  color: ${({ theme }) => theme.secondaryText};  

  &:focus {
    border: none;
    outline: 0;
  }
`;