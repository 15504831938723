import styled from "styled-components";

export const Container = styled.main`
    width: 100vw;
    min-height: 100vh;

    height: fit-content;
    padding: ${(props) => props.id === 'início' ? '0 65px' : '100px 65px'};

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 900px) {
        padding: 0 10px;
        margin: 0;
    }

    @media (max-width: 1139px) {
        width: 100vw;
    }
`;

export const Content = styled.div`
    width: 100%;
    max-width: 1140px;
    height: 100%;

    padding: 0 65px;
    
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    @media (max-width: 600px) {
        padding: 0 10px;
    }
`;

export const BorderContainer = styled.div`
    width: 100%;
    max-width: 1140px;

    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 0;
    z-index: 20;

    padding: 10px;

    &::before {
        content: '';
        width: 80%;
        height: 1px;

        background-color: ${({ theme }) => theme.secondaryText};
    }
`;

export const ColumnContainer = styled.div`
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;