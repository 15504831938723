import { useEffect, useRef, useState } from "react";
import { useTranslateContext } from '../../core/providers/translate';
import { AboutScreen } from "../../screens/AboutScreen";
import { useIntersectionObserver } from "../../core/hooks/useObserver";
import { useGitCommits } from "../../core/hooks/useGitCommits";

const ptDescription = `Bem-vindo ao meu portfólio! Meu nome é Jhonata Tenório,
tenho 23 anos e sou um Desenvolvedor Full Stack Pleno. Com uma sólida experiência em várias tecnologias,
incluindo React, NextJS, Nestjs, Styled-components, Typescript, SASS, Nodejs, Git e React-Native,
estou sempre procurando expandir minhas habilidades e conhecimentos para criar soluções excepcionais para meus clientes.
Com uma paixão por criar softwares intuitivos e bem projetados, estou constantemente me esforçando para
superar expectativas e oferecer o melhor resultado possível. Se você está procurando um desenvolvedor confiável,
criativo e experiente, entre em contato comigo.`;

const enDescription = `Welcome to my portfolio! My name is Jhonata Tenório,
I'm 23 years old, and I am a Mid-level Full Stack Developer. With a solid experience in
many technologies, including React, NextJS, Nestjs, styled-components, TypeScript,
SCSS, Node.js, Git, and React Native, I am always seeking to expand my skills and knowledge
to create exceptional solutions for my clients. With a passion for creating intuitive and
well-designed software, I am constantly striving to exceed expectations and deliver the best
possible outcome. If you're looking for a reliable, creative, and experienced developer,
please feel free to contact me.`;

export const AboutContainer = () => {
    const { currentLanguage } = useTranslateContext();
    const { totalRepositories } = useGitCommits();
    const [currentHistoryButton, setHistoryButton] = useState('professional')
    const [isIntersecting, setIsIntersecting] = useState(false);
    const pageRef = useRef();
    const status = useIntersectionObserver(pageRef, {freezeOnceVisible: false});

    useEffect(() => {
        if(status) setIsIntersecting(status.isIntersecting)
    }, [status]);

    const handleCurrentHistoryType = (value) => {
        if(currentHistoryButton !== value) {
            setHistoryButton(value);
        }
    };

    return <AboutScreen
        {...{
            handleCurrentHistoryType,
            currentHistoryButton,
            description: currentLanguage === 'en' ? enDescription : ptDescription, 
            isIntersecting,
            pageRef,
            projectsNumber: totalRepositories || 74,
            commitsNumber: 1204,
            certificatesNumber: 15,
            currentLanguage
        }}
    />
}