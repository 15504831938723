import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { AboutContainer } from "../AboutContainer";
import { HomeContainer } from "../HomeContainer";
import { ProjectContainer } from "../ProjectContainer";
import { SkillsContainer } from "../SkillsContainer";

export const MainLayoutContainer = () => {
  return <>
    <Header />
    <main>
      <HomeContainer/>
      <AboutContainer/>
      <SkillsContainer/>
      <ProjectContainer/>
      <Footer year="2024"/>
    </main>
  </>
}