import { transparentize } from "polished";
import styled from "styled-components";

export const IconSkillCont = styled.div`
  min-width: 115px;
  min-height: 115px;

  border: none;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.skillCardBackground};
  border-bottom: 5px solid ${({ theme }) => theme.secondaryText};

  margin: 1px;

  transition: all .1s;

  &.active {
    background-color: ${({ theme }) => theme.secondaryText};
    border-top: 5px solid ${({ theme }) => theme.skillCardBackground};
  }

  @media (max-width: 1024px) {
    margin: 4px;

    pointer-events: none;

    &:nth-child(2n+1) {
      background-color: ${({ theme }) => theme.secondaryText};
      border-top: 5px solid ${({ theme }) => theme.skillCardBackground};
      
      svg {
        font-size: 80px;
        color: ${({ theme }) => theme.skillCardBackground};
      }
    }
  }

  @media (max-width: 600px) {
    margin: 1px;
    min-width: 90px;
    min-height: 90px;

    &:nth-child(n) {
      svg {
        font-size: 40px;
      }
    }
  }
`;

export const IconContent = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(1) {
    font-size: 60px;
    color: ${({ theme }) => theme.secondaryText};
  }

  &.active {
    color: ${({ theme }) => theme.skillCardBackground};
  }
`;

export const CardContainer = styled.section`
    width: 800px;
    min-height: 351.62px;

    background-color: ${({ theme }) => transparentize(.9, theme.infoCardBackground)};
    border: 1px solid ${({ theme }) => theme.infoCardBackground};

    border-radius: 12px;
    padding: 30px;
     
    color: ${({ theme }) => theme.secondaryText};
    
    h3, h5 {
      color: ${({ theme }) => theme.secondaryText};
      margin-bottom: 15px;
      font-weight: bold;

      span {
        color: ${({ theme }) => theme.secondaryText};
        font-weight: 400;
      }
    }
    
    h3 {
      margin: 25px 0;
    }

    p {
      font-weight: 600;
    }

    @media (max-width: 1024px) {
      &.primary__card {
        display: none;
      }
    }

`;

export const CardImgCont = styled.div`
  height: 100%;
  width: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  right: 0;

  background-color: ${({ theme }) => theme.skillCardBackground};
  color: ${({ theme }) => theme.secondaryText};

  padding: 20px;
  margin: 0;

  p {
    text-align: center;
    font-size: 14px;
    padding: 0 34px;
  }

  @media(max-width: 900px) {
    height: 65%;
    width: 100%;

    top: 35%;

    h3 {
      font-size: 24px;
    }
  }
  
`;

export const ButtonSection = styled.div`
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 20px;

    position: absolute;
    bottom: 0;
    transition: all .5s;

`;