import { ThemeProvider } from "styled-components";
import { Global } from './styles/global';
import { lightTheme, darkTheme } from './styles/Theme'; 
import { useThemeContext } from "./core/providers/theme";
import { RouterProvider } from "react-router-dom";
import { routerList } from "./routes";

const App = () => {
  const { theme } = useThemeContext();

  return <>
    <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
      <Global/>
      <RouterProvider router={routerList}/>
    </ThemeProvider>
  </>
}

export default App;
