import { useState } from 'react';
import { BsTranslate } from 'react-icons/bs';
import { useTranslateContext } from '../../core/providers/translate';
import { IconContainer, ToggleContainer } from "./styles";

export const TranslateToggle = () => {
    const { toggleCurrentLanguage, currentLanguage } = useTranslateContext();
    const [isEnglishVersion, setIsEnglishVersion] = useState(currentLanguage === 'en');

    const toggleLanguage = () => {
        toggleCurrentLanguage(isEnglishVersion ? 'pt' : 'en');
        setIsEnglishVersion(!isEnglishVersion);
    };

    return <ToggleContainer isEnglish={isEnglishVersion} onClick={toggleLanguage}>
        <IconContainer>
            <BsTranslate/>
        </IconContainer>
    </ToggleContainer>
}