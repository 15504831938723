import { IoMdSunny, IoMdMoon } from 'react-icons/io';
import { SwitchContainer } from "./style"
import { useThemeContext } from '../../core/providers/theme';

export const SwitchThemeMode = () => {
  const { theme, toggleTheme } = useThemeContext();

    return <SwitchContainer onClick={toggleTheme}>
        <IoMdMoon className={theme === 'dark' && 'active'}/>
        <IoMdSunny className={theme === 'light' && 'active'}/>
    </SwitchContainer>
}