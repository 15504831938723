import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeContextProvider } from './core/providers/theme';
import { TranslateContextProvider } from './core/providers/translate';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <TranslateContextProvider>
        <App/>
      </TranslateContextProvider>
    </ThemeContextProvider>
  </React.StrictMode>
);
