import { DefaultButton } from "../../components/Button";
import { CardInfo, CardKpi } from "../../components/CardInfo";
import { DefaultContainer } from "../../components/Layout"
import { ProfilePicture } from "../../components/ProfilePicture";
import { Timeline } from "../../components/Timeline";
import { AcademicItems, EnAcademicItems, EnProfessionalItems, ProfessionalItems } from "../../core/mocks/timeline";
import { AboutContainer, AboutContent, KpiContainer, HistoricContainer, ButtonContainer } from "./styles";

export const AboutScreen = ({
  handleCurrentHistoryType,
  currentHistoryButton,
  description,
  pageRef,
  isIntersecting,
  projectsNumber,
  commitsNumber,
  certificatesNumber,
  currentLanguage
}) => {
  return <DefaultContainer id="sobre">
    <div ref={pageRef} />
    <AboutContainer>
        <AboutContent>
          <CardInfo>
            <ProfilePicture />
            <div>
              <h2>{currentLanguage === 'pt' ? 'Sobre' : 'About'}</h2>
              <p>{description}</p>
            </div>
          </CardInfo>
        </AboutContent>
      <HistoricContainer>
        <CardInfo className="history">
            <h2>{currentLanguage === 'pt' ? 'Histórico' : 'History'}</h2>
            <ButtonContainer>
              <DefaultButton
                selected={currentHistoryButton === 'professional'}
                label={currentLanguage === 'pt' ? 'Profissional' : 'Professional'}
                handleClick={() => handleCurrentHistoryType('professional')}
              />
              <DefaultButton
              selected={currentHistoryButton === 'academic'}
                label={currentLanguage === 'pt' ? 'Acadêmico' : 'Academic'}
                handleClick={() => handleCurrentHistoryType('academic')}
              />
            </ButtonContainer>
            {currentHistoryButton === 'professional' && 
              <Timeline
                data={currentLanguage === 'en' ? EnProfessionalItems : ProfessionalItems}
              />
            }
            {currentHistoryButton === 'academic' && 
              <Timeline
                data={currentLanguage === 'en' ? EnAcademicItems : AcademicItems}
              />
            }
        </CardInfo>
      </HistoricContainer>
      <KpiContainer>
        <CardKpi
          isIntersecting={isIntersecting}
          total={projectsNumber}
          title={currentLanguage === 'pt' ? 'Projetos' : 'Projects'}
        />
        <CardKpi
          isIntersecting={isIntersecting}
          total={commitsNumber}
          title="Commits"
        />
        <CardKpi
          isIntersecting={isIntersecting}
          total={certificatesNumber}
          title={currentLanguage === 'pt' ? 'Certificados' : 'Certificates'}
          variation="green"
        />
      </KpiContainer>
    </AboutContainer>
  </DefaultContainer>
}