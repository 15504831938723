import styled from "styled-components";

export const Container = styled.main`
  height: 100%;
  max-width: 1140px;
  width: 100%;

  padding: 10px 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;

  @media (max-width: 1124px) {
    grid-template-columns: 1fr;

    padding: 100px 0;
    margin-top: 10px;
  }
`;

export const Content = styled.div`
  width: 100%;
    
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;        

  .card-link__container {
    display: flex;
    a {
      display: flex;
      gap: 5px;
        
      & + a {
        margin-left: 10px;
      }

      transition: color .3s;

      &:hover {
        color: ${({ theme }) => theme.mainText}
      }
    }
  }
`;