import { useEffect, useState } from "react"
import { api } from "../services/api";


const loadData = async () => {
    const headers = {"Authorization": `Bearer ${process.env.REACT_APP_GIT_TOKEN}`};
    const response = await api.get(
        `https://api.github.com/users/jhonatat/repos?sort=pushed&per_page=100`,
        { headers }
    );

    return { totalRepositories: response.data.length };
}

export const useGitCommits = () => {
    const [repositories, setRepositories] = useState({});

    useEffect(() => {
        loadData()
        .then(result => {
            setRepositories(result);
        });
    }, []);


    return repositories;
}