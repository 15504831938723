import styled from "styled-components";

export const TimelineContainer = styled.div`
    width: 100%;
    height: 100%;

    margin-top: 10px;

    overflow-y: auto;

    .timeline-event {
        background-color: transparent;

        &.active .timeline-event-content {
            height: auto;
            p {
                white-space: normal;
                overflow: auto;
                text-overflow: none;
    
                overflow-y: hidden;
            }
        }
        
        .timeline-event-content {
            height: 100px;

            width: 100%;

            p {
                text-align: left;
                white-space: nowrap;
                line-height: 1.25rem;
                overflow: hidden;
                text-overflow: ellipsis;
    
                overflow-y: hidden;
            }
    
            > span {
                width: 100%;
                color: #13ab7e;

                cursor: pointer;

                transition: filter .2s;

                &:hover {
                    filter: brightness(.9)
                }
            }
        }
    }

    &::-webkit-scrollbar {
        width: .2em;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px ${({ theme }) => theme.primaryBackground};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.secondaryText};
        outline: 1px solid slategrey;
    }
`;

export const TimelineContent = styled.div``;