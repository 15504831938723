import { BsGlobe } from "react-icons/bs";
import { DefaultContainer } from "../../components/Layout";
import { ProjectCard } from "../../components/ProjectCard";
import { Container, CardsContainer, Content, ButtonContainer } from "./style";
import { SiGithub } from "react-icons/si";
import { Link } from "react-router-dom";
import { useTranslateContext } from "../../core/providers/translate";

export const ProjectScreen = ({ projectList }) => {
  const { currentLanguage } = useTranslateContext();

  return <DefaultContainer id="projetos" color="tertiary">
    <Container>
      <CardsContainer>
        {Array.isArray(projectList) &&
          projectList.map(project => (
            <Content key={Math.random()}>
              <ProjectCard
                {...project}
                image={require(`../../assets/images/${project?.image}`)}
              />
              <div className="card-link__container">
                <a href={project?.codeLink} target="__blank">
                  <span><SiGithub  size={18}/></span>
                  <span>{currentLanguage === 'pt' ? 'Código' : 'Code'}</span>
                </a>
                <a href={project?.siteLink} target="__blank">
                  <span><BsGlobe  size={18}/></span>
                  <span>{currentLanguage === 'pt' ? 'Ao vivo' : 'Live'}</span>
                </a>
              </div>
            </Content>
          ))
        }
      </CardsContainer>
      <ButtonContainer>
        <button type='button'>
          <Link to='/projects'>
            {currentLanguage === 'pt' ? 
              'Ver todos os projetos' : 
              'See all'
            }
          </Link>
        </button>
      </ButtonContainer>
    </Container>
  </DefaultContainer>
}