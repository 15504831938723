import { useEffect, useRef, useState } from "react";
import { useTranslateContext } from '../../core/providers/translate';
import { HomeScreen } from "../../screens/HomeScreen";
import { useIntersectionObserver } from "../../core/hooks/useObserver";

const subTitleVariations = {
    pt: 'Desenvolvedor Full Stack',
    en: 'Full Stack Developer'
};

export const HomeContainer = () => {
    const pageRef = useRef();
    const { currentLanguage } = useTranslateContext();
    const status = useIntersectionObserver(pageRef, {freezeOnceVisible: false});
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [subtitle, setSubtitle] = useState(subTitleVariations[currentLanguage || 'pt']);

    useEffect(() => {
        if(status) setIsIntersecting(status.isIntersecting)
    }, [status]);

    useEffect(() => {
        if(isIntersecting) {
            setSubtitle(subTitleVariations[currentLanguage || 'pt'])
        }
    }, [isIntersecting, currentLanguage])

    return <HomeScreen {...{subtitle, pageRef}}/>
}