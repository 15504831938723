import { useState } from 'react';
import { Timeline as CustomTimeline, TimelineEvent } from 'react-event-timeline'
import { MdWork } from 'react-icons/md'; 
import { TimelineContainer } from "./styles";

export const Timeline = ({ data = [] }) => {
    const [descriptionOpen, setDescriptionOpen] = useState(0);

    const currentIconStyle = {
        backgroundColor: '#ffffff',
        border: `1px solid #000000`,
    };

    return <TimelineContainer>
        <CustomTimeline className="custom-container">
            {
                data.map((item, idx) => (
                    <TimelineEvent
                        key={idx}
                        className={`timeline-event ${descriptionOpen === idx ? 'active' : ''}`}
                        bubbleStyle={currentIconStyle}
                        title={`${item.title}`}
                        titleStyle={{textTransform: 'uppercase', fontWeight: 'bold'}}
                        subtitle={`${item.cardTitle}`}
                        subtitleStyle={{fontWeight: 'bold', textTransform: 'uppercase'}}
                        icon={<MdWork color='#000000'/>}
                        cardHeaderStyle={{backgroundColor: '#ffffff'}}
                        contentStyle={{backgroundColor: 'transparent', boxShadow: 'none'}}
                    >
                        <div className="timeline-event-content">
                            <p>{item.cardDetailedText}</p><br/><br/>
                            <span onClick={() => setDescriptionOpen(idx)}>
                                {descriptionOpen === idx ? '' : 'Ver mais...'}
                            </span>
                        </div>
                    </TimelineEvent>
                ))
            }
        </CustomTimeline>
    </TimelineContainer> 
    
}