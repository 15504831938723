import AnimatedText from 'react-animated-text-content';
import { LinkButton } from '../../components/Button';
import { DefaultContainer } from '../../components/Layout';
import { HomeContainer, HomeContent } from './styles';

export const HomeScreen = ({ subtitle, pageRef }) => {
    const animatedProps = {
        type: "chars",
        animation: {
            x: '200px',
            y: '-20px',
            scale: 1.1,
            ease: 'ease-in-out',
        },
        animationType: "float",
        interval: 0.04,
        duration: .9,
        ease: 'ease',
        scale: .27,
        tag: "h1",
        className: "animated-paragraph",
        includeWhiteSpaces: true,
        threshold: 0.1,
        rootMargin: "0%",
    };

    return <DefaultContainer id="início">
        <HomeContainer ref={pageRef}>
            <HomeContent>
                <div>
                    <h1>Jhonata Tenório</h1>
                    <AnimatedText {...animatedProps} >
                        {subtitle}
                    </AnimatedText>
                </div>
                <LinkButton label="Saber mais" to="Sobre" />
            </HomeContent>
        </HomeContainer>
    </DefaultContainer>
}