import styled from "styled-components";

export const CardRow = styled.section`
    display: flex;
`;

export const CardsContainer = styled.div`
    max-width: 60%;

    display: flex;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
        width: .2em;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px ${({ theme }) => theme.infoCardBackground};
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }

    @media (max-width: 1024px) {
        max-width: 100%;
        justify-content: center;
        margin: 20px 0;
    }
`;