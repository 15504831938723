import styled from "styled-components";

export const ToggleContainer = styled.div`
    height: 25px;
    
    display: flex;
    align-items: center;
    gap: 5px;

    margin-left: 15px;

    cursor: pointer;

    border-radius: 1rem;

    &:before {
        content: 'PT';
        color: ${({ theme, isEnglish }) => isEnglish ? theme.secondaryText : theme.mainText};
    }

    &:after {
        content: 'EN';
        color: ${({ theme, isEnglish }) => isEnglish ? theme.mainText : theme.secondaryText};
    }
`;

export const IconContainer = styled.span`
    height: 100%;

    display: flex;
    align-items: center;

    font-size: 16px;
    color: ${({ theme }) => theme.secondaryText}
`;