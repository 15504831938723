export const ProfessionalItems = [
    {
        title: "Fev/2023",
        cardTitle: "DESENVOLVEDOR PLENO",
        cardSubtitle:"FULL STACK",
        cardDetailedText: "Durante os últimos meses, tive a oportunidade de trabalhar em um cargo desafiador em uma empresa inovadora. Como parte da equipe de tecnologia, tive acesso a diversas tecnologias de ponta, incluindo React, React Native, Docker, Git, Google Cloud, MariaDB, Firebase, NestJS e BigQuery. Essas ferramentas me permitiram contribuir para o desenvolvimento de aplicativos sofisticados e soluções de back-end robustas.",
    },
    {
        title: "Ago/2022",
        cardTitle: "Analista de Desenvolvimento",
        cardSubtitle: "Software",
        cardDetailedText: "De Agosto de 2022 até Fevereiro de 2023, tive a oportunidade de trabalhar em um cargo desafiador. Minha principal responsabilidade foi liderar o desenvolvimento de um aplicativo para a EMBRAPA, que tinha o objetivo de coletar dados e apresentar cálculos complexos relacionados à criação de suínos. Para isso, utilizei minhas habilidades em React Native para desenvolver uma interface atraente e fácil de usar para os usuários do aplicativo. Trabalhei em estreita colaboração com os usuários finais e outros membros da equipe para garantir que o aplicativo fosse funcional e atendesse às necessidades dos usuários.",
    },
    {
        title: "Mai/2021",
        cardTitle: "Desenvolvedor Full Stack",
        cardSubtitle: "Jr.",
        cardDetailedText: "Minha principal responsabilidade foi trabalhar em um painel administrativo feito em ReactJS, com o backend em NestJS, que exigia a resolução de bugs e a criação de novas funcionalidades para melhorar a experiência do usuário. Trabalhei em estreita colaboração com outros membros da equipe, incluindo designers e desenvolvedores, para garantir que o painel administrativo fosse funcional e atendesse às necessidades dos usuários.",
    },
    {
        title: "Mar/2020",
        cardTitle: "Desenvolvedor Web",
        cardSubtitle: "Freelancer",
        cardDetailedText: "Para fortalecer os estudos na área de Desenvolvimento Web, comecei a pegar pequenos projetos com HTML, CSS3 e JavaScript. Mantendo, em paralelo, os estudos em NodeJS, ReactJS, entre outras tecnologias",
    },
];

export const EnProfessionalItems = [
    {
        title: "Feb/2023",
        cardTitle: "Mid-level Full Stack Developer",
        cardSubtitle:"FULL STACK",
        cardDetailedText: "Over the past few months, I had the opportunity to work in a challenging position at an innovative company. As part of the technology team, I had access to various cutting-edge technologies, including React, React Native, Docker, Git, Google Cloud, MariaDB, Firebase, NestJS, and BigQuery. These tools allowed me to contribute to the development of sophisticated applications and robust backend solutions.",
    },
    {
        title: "Aug/2022",
        cardTitle: "Software Development Analyst",
        cardSubtitle: "Software",
        cardDetailedText: `From August 2022 to February 2023, I had the opportunity to work in a challenging position. My main responsibility was to lead the development of an application for EMBRAPA, which aimed to collect data and present complex calculations related to pig farming. To achieve this, I utilized my skills in React Native to develop an attractive and user-friendly interface for the application's users. I worked closely with end-users and other team members to ensure the app was functional and met user needs.`,
    },
    {
        title: "May/2021",
        cardTitle: "Full Stack Developer",
        cardSubtitle: "Jr.",
        cardDetailedText: "My main responsibility was to work on an administrative dashboard built with ReactJS, with the backend in NestJS, which required bug fixing and creating new functionalities to enhance the user experience. I worked closely with other team members, including designers and developers, to ensure the administrative dashboard was functional and met user needs.",
    },
    {
        title: "Mar/2020",
        cardTitle: "Web Developer",
        cardSubtitle: "Freelancer",
        cardDetailedText: "To strengthen my studies in Web Development, I started taking on small projects involving HTML, CSS3, and JavaScript. Concurrently, I continue my studies in NodeJS, ReactJS, and other technologies.",
    },
]

export const AcademicItems = [
    {
        title: "Set/2022",
        cardTitle: "Especialização em JS",
        cardSubtitle:"JavaScript Expert",
        cardDetailedText: "O curso JavaScript Expert ministrado por Erick Wendell foi uma experiência incrível que me proporcionou uma compreensão mais profunda do desenvolvimento de software e aprimorou minhas habilidades em JavaScript. Durante o curso, aprendi diversos tópicos relevantes para o mercado de tecnologia atual, desde as bases do JavaScript até o desenvolvimento de aplicações em tempo real.",
    },
    {
        title: "Jun/2021",
        cardTitle: "Especialização em React",
        cardSubtitle: "Rocketseat: Ignite",
        cardDetailedText: "No geral, o curso Ignite da Rocketseat, na trilha React, foi uma oportunidade valiosa para aprimorar minhas habilidades em desenvolvimento web com o React. Aprendi muitas técnicas avançadas de programação que me ajudarão a desenvolver aplicações web mais eficientes e escaláveis no futuro. Sou grato à equipe da Rocketseat pelo excelente trabalho realizado na organização do curso e pela experiência valiosa que foi oferecida aos participantes.",
    },
    {
        title: "Set/2019",
        cardTitle: "Ciência da Computação",
        cardSubtitle: "UFAL",
        cardDetailedText: "O meu primeiro contato com o mundo da programação e início da graduação no curso de Ciência da Computação na Univerdade Federal de Alagoas.",
    }
];

export const EnAcademicItems = [
    {
        title: "Sep/2022",
        cardTitle: "Specialization in JS",
        cardSubtitle:"JavaScript Expert",
        cardDetailedText: "The JavaScript Expert course taught by Erick Wendell was an incredible experience that provided me with a deeper understanding of software development and enhanced my skills in JavaScript. Throughout the course, I learned various topics relevant to the current technology market, ranging from the fundamentals of JavaScript to real-time application development.",
    },
    {
        title: "Jun/2021",
        cardTitle: "Specialization in React",
        cardSubtitle: "Rocketseat: Ignite",
        cardDetailedText: "Overall, the Ignite course by Rocketseat, in the React track, was a valuable opportunity to enhance my skills in web development with React. I learned many advanced programming techniques that will help me build more efficient and scalable web applications in the future. I am grateful to the Rocketseat team for their excellent work in organizing the course and providing participants with such a valuable experience.",
    },
    {
        title: "Sep/2019",
        cardTitle: "Computer Science",
        cardSubtitle: "Federal University of Alagoas",
        cardDetailedText: "My first contact with the programming world began when I started my undergraduate studies in Computer Science at the Federal University of Alagoas.",
    }
];
