import { useState } from "react";
import { Card, SkillCard } from "../../components/Card";
import { DefaultContainer } from "../../components/Layout";
import { CardRow, CardsContainer } from "./style";
import { technologiesDescription } from "../../core/mocks/technologies";
import { useTranslateContext } from "../../core/providers/translate";

export const SkillScreen = () => {
  const { currentLanguage } = useTranslateContext();
  const [cardActive, setCardActive] = useState('React JS');
  const selectedIcon = technologiesDescription.filter(item => item.name === cardActive)[0];

  return <DefaultContainer id="tecnologias">
    <CardRow>
      <CardsContainer>
        {
          technologiesDescription && technologiesDescription.map((icon, index) => 
            <SkillCard
              key={index}
              onHover={() => setCardActive(icon.name)}
              className={cardActive === icon.name ? "active" : ""}
              Icon={() => <icon.Component className="icon" />}
            />
          )
        }
      </CardsContainer>
      <Card
        title={currentLanguage === 'pt' ? 'Experiência' : 'Experience'}
        subtitle={selectedIcon?.name}
        comments={selectedIcon?.experienceTime[currentLanguage]}
        description={selectedIcon?.description[currentLanguage]}
        currentLanguage={currentLanguage}
      />
    </CardRow>
  </DefaultContainer>
}