import { transparentize } from "polished";
import styled from "styled-components";

export const CardContainer = styled.div`
    width: 100%;

    position: relative;
`;

export const CardWithImage = styled.div`
    width: 100%;
    height: 260px;

    background-color: ${({ theme }) => transparentize(.0, theme.projectCardBackground)};
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        height: 80%;
        width: 80%;

        position: absolute;
        object-fit: contain;

        border-radius: 6px;
        opacity: .5;
    }
`;

export const CardContent = styled.div`
    width: 100%;
    height: 260px;
    
    position: absolute;
    top: 8px;
    left: 8px;

    z-index: 20;

    background-color: ${({ theme }) => transparentize(.6, theme.backgroundCard)};
    border: 1px solid ${({ theme }) => theme.backgroundCard};
    border-radius: 6px;

    backdrop-filter: blur(5.8px);
    -webkit-backdrop-filter: blur(5.8px);

    transition: border .2s;

    &:hover {
        border: 1px solid #ffffff;
    }

    > div {
        height: 100%;

        display: flex;
        flex-direction: column;

        color: ${({theme}) => theme.whiteText};

        &.front-card__container {
            > span {
                margin-top: 12px;
                h3 {
                    font-size: 24px;
                    text-align: center;
                }
        
                p {
                    padding: 10px 70px;
                    text-align: justify;
                }
            }

            .technologies__container {
                width: 100%;

                margin-top: 20px;

                padding: 0 40px;

                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                gap: 5px;

                position: absolute;
                bottom: 20px;
            }
        }
    }

    @media (max-width: 600px) {
        > div {
            &.front-card__container {
                span {
                    h3 {
                        font-size: 24px;
                        text-align: center;
                    }
            
                    p {
                        font-size: 13px;
                        padding: 20px;
                        text-align: center;
                    }
                }
            }
        }
    }
`;