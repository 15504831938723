import { BsSearch } from 'react-icons/bs';
import { TiArrowBackOutline } from 'react-icons/ti'; 
import { SocialIcon } from "../Icons";
import { SwitchThemeMode } from "../Switch";
import { Link } from 'react-router-dom';
import { useTranslateContext } from '../../core/providers/translate';
import { HeaderContainer, HeaderContent, IconsCont, LeftArea, Search, SearchContainer } from "./styles";
import { TranslateToggle } from '../TranslateToggle';

export const ProjectHeader = ({ handleSearch, search }) => {
  const { currentLanguage } = useTranslateContext();

  return <HeaderContainer>
    <HeaderContent>
      <LeftArea>
        <Link to='/' className='icon__container'>
          <TiArrowBackOutline/>
        </Link>
        <SearchContainer>
          <Search
            placeholder={
              currentLanguage === 'pt' ?
              'Pesquise por tecnologia (separando por vírgula)' :
              'Search by technology (separating by comma)'
            }
            onChange={handleSearch}
            value={search}
          />
          <span><BsSearch/></span>
        </SearchContainer>
      </LeftArea>
      <IconsCont>
        <SwitchThemeMode/>
        <TranslateToggle current={currentLanguage}/>
        <SocialIcon iconType="github" />
        <SocialIcon iconType="linkedin" />
      </IconsCont>
    </HeaderContent>
  </HeaderContainer>
}