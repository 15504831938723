import { useTranslateContext } from "../../core/providers/translate";
import { ChipComponent } from "../Chip";
import { CardContainer, CardContent, CardWithImage } from "./styles";

export const ProjectCard = ({ image, title, description, technologies, siteLink, codeLink }) => {
    const { currentLanguage } = useTranslateContext();

    return <CardContainer>
        <CardWithImage>
            <img src={image} alt={title}/>
        </CardWithImage>
        <CardContent>
            <div className="front-card__container">
                <span>
                    <h3>{title[currentLanguage]}</h3>
                    <p>{description[currentLanguage]}</p>
                </span>
                <div className="technologies__container">
                    {
                        Array.isArray(technologies) && technologies.map(tech => (
                            <ChipComponent
                                key={Math.random()}
                                color={tech.color}
                                backgroundColor={tech.backgroundColor}
                                label={tech.label}
                            />
                        ))
                    }
                </div>
            </div>
        </CardContent>
    </CardContainer>
}