import styled from "styled-components";

export const Container = styled.section`
    width: 100%;
    min-height: 100%;

    padding: 15px 0 10% 0;
`;

export const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;

    @media (max-width: 1124px) {
        grid-template-columns: 1fr;
    }
`;

export const Content = styled.div`
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;        

    .card-link__container {
        display: flex;
        a {
            display: flex;
            gap: 5px;
            
            & + a {
                margin-left: 10px;
            }

            transition: color .3s;

            &:hover {
                color: ${({ theme }) => theme.mainText}
            }
        }
    }
`;

export const ButtonContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    margin-top: 50px;

    padding: 0 2rem;

    button {
        width: 100%;
        height: 3rem;

        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.mainText};
        border-radius: 6rem;

        cursor: pointer;

        position: relative;
        overflow: hidden;

        transition: all .2s;
        
        a {
            height: 3rem;
            width: 100%;
            
            display: flex;
            align-items: center;
            justify-content: center;

            padding: 0 2rem;

            font-size: 1rem;
            font-weight: bold;
            color: ${({ theme }) => theme.mainText};
        }
        
        &:hover {
            background-color: ${({ theme }) => theme.skillCardBackground};

            a {
                color: ${({ theme }) => theme.secondaryText};
            }
        }
    }
`;
