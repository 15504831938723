import { useSpring, animated } from "@react-spring/web";
import { CardInfoContainer, CardKpiContainer } from "./styles";

export const CardInfo = ({ children, variation, className }) => {
    return <CardInfoContainer isGreen={variation === 'green'} className={className}>
        {children}
    </CardInfoContainer>
}

export const CardKpi = ({ variation, total, title, isIntersecting }) => {
    const { number } = useSpring({
        from: { number: 0 },
        number: isIntersecting ? total : 0,
        delay: 200,
        config: { mass: 1, tension: 20, friction: 10 }
    });

    return <CardKpiContainer isActive={variation === 'green'}>
        <h2>{title}</h2>
        <animated.span>{number.to((n) => n.toFixed(0))}</animated.span>
    </CardKpiContainer>
}