import { darken, transparentize } from "polished";
import styled from "styled-components";

export const ButtonContainer = styled.div`
    height: 35px;
    width: 180px;

    background-color: transparent;
    border-radius: 10px;

    background-color: ${({ theme }) => theme.secondaryText};

    margin-top: 20px;
    
    cursor: pointer;

    z-index: 200;
    
    transition: width .35s;
    
    &.--outline {
        width: 100px;
        margin-top: 10px;
        background-color: ${({ theme }) => theme.primaryBackground};

        transition: .4s,background-position 0s;

        a {
            color: ${({ theme }) => theme.secondaryText};
            font-weight: 400;
        }

        &:hover {
            width: 100px;
            background-color: ${({theme}) => darken(.2, theme.primaryBackground)}
        }
    }
    
    a {
        height: 100%;
        width: 100%;
        
        display: flex;
        align-items: center;
        justify-content: center;
        
        font-size: 16px;
        color: ${({ theme }) => theme.primaryBackground};
    }

    &:hover {
        width: 200px;
    }
`;

export const CustomButtonContainer = styled.button`
    width: 100%;

    background-color: ${({ theme, ...props }) => 
        transparentize(.9, props.isSelected ? theme.activeCard : theme.infoCardBackground)
    };

    border: none;
    border-radius: 3px;
    padding: 10px;
    cursor: pointer;

    border: 1px solid ${({ theme, ...props }) => 
        transparentize(.6, props.isSelected ? theme.activeCard : theme.infoCardBackground)
    };

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 700;

    transition: background-color .2s;

    span {
        color: ${({ theme }) => theme.secondaryText};
    }

    &:hover {
        background-color: ${({ theme }) => transparentize(.9, theme.activeCard)};
        border: 1px solid ${({ theme, ...props }) => transparentize(.6, theme.activeCard)};
    }
`;