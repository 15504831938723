import styled from "styled-components";

export const IconCont = styled.div`
    width: 25px;
    height: 25px;
    margin-left: 15px;

    a {
        color: ${({ theme }) => theme.secondaryText};
        font-size: 25px;

        transition: color .3s;

        &:hover {
            color: ${({ theme }) => theme.mainText};
        }
    }
`;