import { transparentize } from "polished";
import styled from "styled-components";

export const ChipContainer = styled.div`
    height: fit-content;
    width: fit-content;

    padding: 2px 10px;

    border-radius: 20px;

    background-color: ${({ backgroundColor }) => transparentize(.44, backgroundColor)};
    color: ${({ color }) => color};

    border: 1px solid ${({ backgroundColor }) => backgroundColor};

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
        padding: 0px 10px;

        span {
            font-size: 12px;
        }
    }
`;