import { useEffect, useState } from 'react';
import { SiGithub } from 'react-icons/si';
import { BsGlobe } from 'react-icons/bs';
import { DefaultContainer } from "../../components/Layout";
import { ProjectCard } from "../../components/ProjectCard";
import { ProjectHeader } from '../../components/ProjectHeader';
import { Container, Content } from './styles';
import { useTranslateContext } from '../../core/providers/translate';

export const ProjectListScreen = ({ projectData = [] }) => {
  const { currentLanguage } = useTranslateContext();
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState(projectData);

  const getSearchableData = () => {
    try {
      const newFilteredData = projectData.filter(row => {
        const searchArr = search.trim().toLowerCase().split(',');
        const technologies = row?.technologies.map(item => item.label.toLowerCase());

        return searchArr
          .filter(item => technologies.find(r => r.toLowerCase().includes(item)))
          .length;          
      });
      
      setFilteredData(newFilteredData);
    } catch(error) {
      console.log("Error -> ", error);
    }
  }

  useEffect(() => {
    if(search) {
      getSearchableData();
    }

    return () => setFilteredData(projectData);
  }, [search])

  const handleSearch = (e) => setSearch(e.target.value);

  return <DefaultContainer>
    <Container>
      <ProjectHeader handleSearch={handleSearch} search={search} />
        {Array.isArray(filteredData) && filteredData.map(project => (
            <Content key={Math.random()}>
              <ProjectCard
                {...project}
                image={require(`../../assets/images/${project?.image}`)}
              />
              <div className="card-link__container">
                <a href={project?.codeLink} target="__blank">
                  <span><SiGithub  size={18}/></span>
                  <span>
                    {currentLanguage === 'pt' ? 'Código' : 'Code'}
                  </span>
                </a>
                {project?.siteLink &&
                  <a href={project?.siteLink} target="__blank">
                    <span><BsGlobe  size={18}/></span>
                    <span>
                      {currentLanguage === 'pt' ? 'Ao vivo' : 'Live'}
                    </span>
                  </a>
                }
              </div>
            </Content>
          ))
        }
    </Container>
  </DefaultContainer>

}