export const lightTheme = {
	mainText: '#F37055',
	whiteText: '#ffffff',
	secondaryText: '#171B27',
	activeCard: '#f37055',

	primaryBackground: '#f2f2f2',
	infoCardBackground: '#171b27',
	skillCardBackground: '#FB8870',
	projectCardBackground: '#FFFFFF',

	bubble: {
		primary: '#FB8870',
		secondary: '#FB8870',
		tertiary: '#FB8870'
	},

	backgroundCard: '#3b3c41',
	
}

export const darkTheme = {
	mainText: '#6EDEF6', // 6EDEF6
	whiteText: '#ffffff',
	secondaryText: '#FFFFFF',
	activeCard: '#FFFFFF',

	infoCardBackground: '#6EDEF6',
	skillCardBackground: '#54ACBF',
	projectCardBackground: '#54ACBF',

	primaryBackground: '#171B27',
	bubble: {
		primary: '#6EDEF6',
		secondary: '#6EDEF6',
		tertiary: '#6EDEF6'
	},

	backgroundCard: '#3b3c41',
}