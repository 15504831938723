import { createContext, useContext, useState } from "react";

const TranslateContext = createContext();

export const TranslateContextProvider = ({ children }) => {
    const [currentLanguage, setCurrentLanguage] = useState('pt');

    const toggleCurrentLanguage = (value = 'pt') => {
        if(value === 'pt' || value === 'en') {
            setCurrentLanguage(value);
        }
    } 

    return <TranslateContext.Provider value={{ toggleCurrentLanguage, currentLanguage }}>
        {children}
    </TranslateContext.Provider>
};

export const useTranslateContext = () => {
    return useContext(TranslateContext); 
};