import styled from "styled-components";

export const AboutContainer = styled.section`
    width: 100%;
    height: fit-content;

    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 5px;

    grid-template-areas: "profileCard history kpi";

    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 1124px) {
        grid-template-columns: 1fr;
        grid-template-areas: 
        "profileCard"
        "kpi"
        "history";

        padding-bottom: 30px;
    }
`;

export const AboutContent = styled.div`
    grid-area: profileCard;
`;

export const KpiContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;

    grid-area: kpi;

    @media (max-width: 1124px) {
        height: 100%;
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    }
`;

export const HistoricContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 5px;

    grid-area: history;

    @media (max-width: 1124px) {
        flex-direction: column;
    }
`;

export const ButtonContainer = styled.div`
    width: 100%;
    
    display: flex;
    gap: 5px;

    margin-top: 10px;
`;
