import styled from "styled-components";
import { transparentize } from "polished";

export const CardInfoContainer = styled.div`
    height: 610px;
    width: 350px;

    background-color: ${({theme, ...props}) => transparentize(.9, props.isActive ? theme.activeCard : theme.infoCardBackground)};
    border: 1px solid ${({theme, ...props}) => props.isActive ? theme.activeCard : theme.infoCardBackground};

    padding: 20px;
    border-radius: 3px;

    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        width: 100%;

        display: flex;
        align-items: center;

        color: ${({ theme }) => theme.secondaryText};

        &:after {
            content: '';
            height: 2px;
            flex: 1;

            margin-left: 10px;
            background-color: ${({ theme }) => theme.secondaryText};
        }
    }

    p {
        text-align: justify;
        font-weight: 200;
        margin-top: 10px;
    }

    @media (max-width: 1124px) {
        height: fit-content;
        min-height: 300px;
        width: 100%;

        padding: 50px 20px;

        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 10px;

        &.history {
            grid-template-columns: 1fr;
        }
    }

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        justify-items: center;
    }
`;

export const CardKpiContainer = styled.div`
    height: 200px;
    width: 300px;
    background-color: ${({theme, ...props}) => transparentize(.9, props.isActive ? theme.activeCard : theme.infoCardBackground)};

    border-radius: 3px;
    padding: 10px;
    border: 1px solid ${({theme, ...props}) => props.isActive ? theme.activeCard : theme.infoCardBackground};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        font-size: 28px;
        font-weight: 400;
    }

    span {
        font-size: 48px;
        font-weight: bold;
    }

    @media (max-width: 1124px) {
        height: 200px;
        width: calc((100% - 10px) / 3);
    }

    @media (max-width: 600px) {
        h2 {
            font-size: 18px;
            font-weight: 400;
        }

        span {
            font-size: 38px;
            font-weight: bold;
        }
    }
`;