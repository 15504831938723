import styled from "styled-components";

export const PictureContainer = styled.div`
    width: 190px;
    height: 190px;

    position: relative;
    top: -40px;

    &:after {
        content: '';
        height: 105%;
        width: 105%;
        position: absolute;

        bottom: -5px;
        left: -5px;

        background: linear-gradient(60deg, #f79533, #f37055, #fdaa47, #a166ab, #293F60, #1098ad, #07b39b, #6fba82);
        border-radius: 50%;
        z-index: 2;
        animation: animatedgradient 5s ease alternate infinite;
        background-size: 300% 300%;
    }

    img {
        width: 100%;
        height: 100%;

        background-color: transparent;
        outline: 5px solid ${({ theme }) => theme.secondaryText};
        
        object-fit: cover;
        position: relative;
        z-index: 3;

        border-radius: 50%;
    }

    @media (max-width: 1124px) {
        top: 0;
    }
`;