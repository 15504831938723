import {
  SiGithub,
  SiLinkedin
} from 'react-icons/si';
import { IconCont } from './style';

const icons = [
  {
    name: 'github',
    Icon: () => <SiGithub />,
    link: 'https://github.com/jhonataT'
  },
  {
    name: 'linkedin',
    Icon: () => <SiLinkedin />,
    link: 'https://www.linkedin.com/in/jhonatat'
  },
];

const IconContainer = ({ children }) => <IconCont>
  {children}
</IconCont>

export const SocialIcon = ({ iconType = 'github' }) => {
  const { Icon, link } = icons.filter( icon => icon?.name === iconType)[0];
  
  return <IconContainer>
    <a href={link || '#'} target="__blank">
      <Icon />
    </a>
  </IconContainer>
}