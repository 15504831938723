import { Link } from "react-scroll";
import {
    CustomButtonContainer,
    ButtonContainer,
} from "./styles";

export const LinkButton = ({ variation, to, label, project, outline = false, disabled }) => {
    return <ButtonContainer
        disabled={disabled}
        className={variation ? `--${variation}` : ''}
    >
        <Link
            to={`${to.toLocaleLowerCase()}`}
            spy={true}
            smooth={true}
            duration={500}
          >
            {label}
          </Link>
    </ButtonContainer>
}

export const DefaultButton = ({ label, selected, handleClick }) => {
    return <CustomButtonContainer type="button" isSelected={selected} onClick={handleClick}>
        <span>{label}</span>
    </CustomButtonContainer>
}