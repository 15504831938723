import { createGlobalStyle } from "styled-components";

export const Global = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    body {
        height: 100%;

        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        letter-spacing: 1px;
        color: ${({ theme }) => theme.secondaryText};

        display: block;

        background-color: ${({ theme }) => theme.primaryBackground};

        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: .5em;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px ${({ theme }) => theme.primaryBackground};
        }

        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
    }

    a {
        text-decoration: none;
        color: ${({ theme }) => theme.secondaryText};
        cursor: pointer;
    }

    h1 {
        font-size: 48px;
        color: ${({ theme }) => theme.secondaryText}; 
    }

    p {
        font-size: 15px;
        font-weight: 300;
    }

    li {
        list-style-type: none;
    }

    @keyframes animatedgradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    @keyframes anim {
        0%,
        100% {
            border-radius: 30% 70% 70% 30% / 30% 52% 48% 70%;
            box-shadow: 0 -2vmin 4vmin ${({ theme }) => theme.bubble.primary} inset, 0 -4vmin 4vmin ${({ theme }) => theme.bubble.tertiary} inset,
                0 -2vmin 7vmin ${({ theme }) => theme.bubble.tertiary} inset;
        }

        10% {
            border-radius: 50% 50% 20% 80% / 25% 80% 20% 75%;
        }

        20% {
            border-radius: 67% 33% 47% 53% / 37% 20% 80% 63%;
        }

        30% {
            border-radius: 39% 61% 47% 53% / 37% 40% 60% 63%;
            box-shadow: 0 -4vmin 8vmin ${({ theme }) => theme.bubble.tertiary} inset, -1vmin -2vmin 6vmin ${({ theme }) => theme.bubble.primary} inset,
                -1vmin -2vmin 4vmin ${({ theme }) => theme.bubble.tertiary} inset, 1vmin 4vmin 8vmin ${({ theme }) => theme.bubble.primary} inset;
        }

        40% {
            border-radius: 39% 61% 82% 18% / 74% 40% 60% 26%;
        }

        50% {
            border-radius: 100%;
            box-shadow: 0 4vmin 16vmin ${({ theme }) => theme.bubble.primary} inset, 0 2vmin 5vmin ${({ theme }) => theme.bubble.tertiary} inset, 0 4vmin 4vmin ${({ theme }) => theme.bubble.tertiary} inset,
                0 6vmin 8vmin ${({ theme }) => theme.bubble.primary} inset;
        }

        60% {
            border-radius: 50% 50% 53% 47% / 72% 69% 31% 28%;
        }

        70% {
            border-radius: 50% 30% 53% 47% / 26% 22% 78% 74%;
            box-shadow: 5vmin 1vmin 8vmin ${({ theme }) => theme.bubble.primary} inset,
                2vmin -1vmin 4vmin ${({ theme }) => theme.bubble.tertiary} inset, -1vmin -1vmin 16vmin ${({ theme }) => theme.bubble.primary} inset;
        }

        80% {
            border-radius: 50% 50% 53% 47% / 26% 69% 31% 74%;
        }

        90% {
            border-radius: 20% 80% 20% 80% / 20% 80% 20% 80%;
        }
    }
`;